.summarize {
    background: #FFFFFF;
    margin-top: 30px;
}

.rss-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 194px;
    height: 47px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    background: #2A68AF;
    border: 0px;
    border-radius: 8px;
}

.add-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 160px;
    height: 47px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    background: #2A68AF;
    border: 0px;
    border-radius: 8px;
}

.summarize-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 125px;
    height: 47px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    background: #2A68AF;
    border: 0px;
    border-radius: 8px;
}

.ant-btn.ant-btn-default:disabled.clear-btn:hover,
.ant-btn.ant-btn-default:disabled.rss-btn:hover,
.ant-btn.ant-btn-default:disabled.add-btn:hover,
.ant-btn.ant-btn-default:disabled.summarize-btn:hover {
    color: #d9d9d9;
}

.ant-btn.clear-btn:hover,
.ant-btn.rss-btn:hover,
.ant-btn.add-btn:hover,
.ant-btn.summarize-btn:hover {
    color: #FFFFFF;
}

.clear-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
    height: 42px;
    background: #2A68AF;
    border-radius: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}

.summarize .ant-picker.search-input,
.summarize .search-input .ant-input-affix-wrapper {
    height: 40px;
}

.summarize .search-input .ant-input-search-button {
    height: 40px;
    width: 46px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.summarize-table .ant-table .ant-table-body {
    min-height: 630px;
    border: 1px solid #f0f0f0;
    border-top: 0px;
}

.summarize-table .ant-table-thead {
    background: linear-gradient(173.17deg, #2BABE2 5.78%, #2C4E83 179.3%);
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
}

.summarize-table .ant-table-thead tr th:nth-last-child(2)::before {
    background-color: transparent !important;
}

.summarize-table .ant-table-tbody tr:nth-last-child(1) td {
    border-bottom: 0px !important;
}

.summarize-table .ant-table-thead tr .ant-table-cell.ant-table-cell-scrollbar {
    box-shadow: unset;
    background: transparent;
}

.summarize-table .ant-table-thead tr th {
    background: transparent;
    color: #FFFFFF;
    padding: 16px 10px 16px 16px;
}

.summarize-table .ant-table-body tr td {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #616060;
    padding: 5px 5px 5px 10px;
}

.summarize-table .article {
    font-weight: 400;
    line-height: 28px;
}

.summarize-table .in-progress {
    background: rgba(214, 162, 67, 0.12);
    border-radius: 40px;
    padding: 5px 12px;
    font-weight: 500;
    color: #D6A243;
}

.summarize-table .completed {
    background: rgba(145, 197, 97, 0.12);
    border-radius: 40px;
    padding: 5px 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    color: #91C561;
}

.not-started-row td {
    background-color: rgba(207, 226, 255, 0.4);
}

.summarize-table .ant-table-thead th .select-all .ant-checkbox-inner,
.not-started-row td .ant-checkbox-inner,
.not-started-row td .ant-checkbox-inner:hover,
.completed-row td .ant-checkbox-inner,
.completed-row td .ant-checkbox-inner:hover,
.failed-row td .ant-checkbox-inner,
.failed-row td .ant-checkbox-inner:hover {
    height: 24px;
    width: 24px;
}

.not-started-row td .ant-checkbox-inner,
.not-started-row td .ant-checkbox-inner:hover {
    border: 2px solid #084298;
}

.completed-row td .ant-checkbox-inner,
.completed-row td .ant-checkbox-inner:hover {
    border: 2px solid #91C561;
}

.failed-row td .ant-checkbox-inner,
.failed-row td .ant-checkbox-inner:hover {
    border: 2px solid #8B0000;
}

.not-started-row td .ant-checkbox-checked .ant-checkbox-inner,
.not-started-row td .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: #084298;
}

.not-started-row td .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #084298;
    border-color: #084298;
}

.completed-row td .ant-checkbox-checked .ant-checkbox-inner,
.completed-row td .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: #91C561;
}

.completed-row td .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #91C561;
    border-color: #91C561;
}

.failed-row td .ant-checkbox-checked .ant-checkbox-inner,
.failed-row td .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: #8B0000;
}

.failed-row td .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #8B0000;
    border-color: #8B0000;
}

.ant-checkbox .ant-checkbox-inner:after {
    width: 5.714286px;
    height: 12.142857px;
}

.in-progress-box {
    height: 25px;
    width: 25px;
    border: 2px solid #D6A243;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.in-progress-box .progress-icon {
    width: 16px;
    height: 16px;
}

.completed-box {
    height: 25px;
    width: 25px;
    border: 2px solid #91C561;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.completed-box .completed-icon {
    width: 15px;
    height: 13px;
}

.in-progress-row td {
    background-color: rgba(214, 162, 67, 0.12);
}

.completed-row td {
    background-color: rgba(145, 197, 97, 0.12);
}

.failed-row td {
    background-color: rgba(139, 0, 0, 0.12);
}

.pdf-icon {
    width: 12px;
    height: 16px;
}

.pdf-white-icon {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    padding: 5px 6px;
    cursor: pointer;
    border-radius: 3px;
    background: linear-gradient(89.17deg, #E03739 0.86%, #92161B 99.48%);
}

.pdf-white-icon img {
    height: 10px;
    width: 24px;
}

.pdf-green-icon {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    padding: 5px 6px;
    cursor: pointer;
    border-radius: 3px;
    background: linear-gradient(90deg, #8FBB67 0.11%, #6A7E57 100.11%);
}

.pdf-green-icon img {
    height: 10px;
    width: 24px;
}

.link-icon {
    display: flex;
    align-items: center;
    padding: 0px 2px;
    border-radius: 3px;
    height: 21px;
    cursor: pointer;
    border: 0.3px solid rgba(0, 0, 0, 1)
}

.link-icon img {
    height: 10px;
    width: 20px;
}

.summarize-confirm-modal .ant-modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.75);
}

.summarize-confirm-modal .ant-modal-title .count {
    color: #2A68AF;
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.summarize-confirm-modal .ant-modal-close {
    height: 30px;
    width: 30px;
}

.summarize-confirm-modal .ant-modal-close-x {
    line-height: 16px;
    color: #000000;
}

.summarize-list {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #6D6D6D;
}

.summarize-table .ant-table-tbody .ant-table-expanded-row-fixed {
    min-height: 540px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.summarize-table .ant-table-tbody .ant-empty .ant-empty-image {
    height: 80px;
}

.summarize-table .ant-table-tbody .ant-empty .ant-empty-image svg {
    height: 80px;
    width: 100px;
}

.summarize-table .ant-table-tbody .ant-empty .ant-empty-description {
    font-size: 18px;
}

.use-text {
    border: 1px solid #000000;
    padding: 0px 8px;
    border-radius: 3px;
    color: #000000;
    cursor: pointer;
}

.use-text.active {
    background-color: #000000;
    color: #FFFFFF;
}

.actionIcon {
    font-size: 18px;
    margin-left: 10px;
}

.ant-modal-confirm.article-confirm-modal .ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: unset;
}