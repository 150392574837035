.article-form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    margin: 36px 0px 10px;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
}

.article-clear-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
    height: 42px;
    border-radius: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    max-width: 120px !important;
}

.article-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
    height: 42px;
    background: #2A68AF;
    border-radius: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    max-width: 120px !important;
}

.ant-btn.ant-btn-default:disabled.article-submit-btn:hover,
.ant-btn.ant-btn-default:disabled.summarize-btn:hover {
    color: #d9d9d9;
}

.ant-btn.article-submit-btn:hover,
.ant-btn.summarize-btn:hover {
    color: #FFFFFF;
}

.search-input .ant-picker-input,
.search-input .ant-input {
    height: 30px;
}

.search-input button {
    height: 40px;
    display: flex;
    align-items: center;
}

.search-input button .anticon.anticon-upload {
    margin-right: 5px;
    margin-bottom: 2px;
}

.errorMessage {
    font-size: 14px;
    color: #ff4d4f;
}

.input-required {
    color: #ff0000e5;
    margin-left: 5px;
}