.login {
    display: flex;
}

.left-layout {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #2BABE2 0%, #2C4E83 100%);
}

.logo-img {
    border: 0px;
}

.right-layout {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box {
    border: 1px solid #2BABE2;
    width: 450px;
    min-width: 300px;
    min-height: 162px;
    margin: 20px;
    padding: 25px 30px;
    background: #FFFFFF;
    border-radius: 20px;
}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.99);
}

.glogin-btn {
    min-width: 260px;
    width: 100%;
    height: 50px;
    display: flex;
    padding: 0px;
    margin: 25px 0px 0px 0px;
    align-items: center;
    justify-content: center;
    background: #4F86EC;
    position: relative;
    border-radius: 5px;
    border: 0px;
}

.glogin-icon {
    width: 48px;
    height: 48px;
    display: flex;
    margin-left: 1px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    background: #FFFFFF;
    border-radius: 5px 3px 3px 5px;
}

.glogin-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
}

@media (max-width: 767.98px) {
    .left-layout{
        height: 40vh;
    }

    .right-layout{
        height: 60vh;
    }
}
