body {
    margin: 0;
    font-family: 'Alegreya', Georgia,Times,Times New Roman,Serif !important;
    --bs-body-font-family: 'Alegreya', Georgia,Times,Times New Roman,Serif;
    --toastify-font-family: 'Alegreya',Georgia,Times,Times New Roman,Serif;
    -webkit-font-smoothing: 'Alegreya',Georgia,Times,Times New Roman,Serif;
    -moz-osx-font-smoothing: 'Alegreya',Georgia,Times,Times New Roman,Serif;
    --bs-tooltip-color: #616060;
    --bs-tooltip-bg: #ffffff;
}

code {
    font-family: 'Alegreya',Georgia,Times,Times New Roman,Serif !important;
    --bs-body-font-family: 'Alegreya',Georgia,Times,Times New Roman,Serif;
    --toastify-font-family: 'Alegreya',Georgia,Times,Times New Roman,Serif;
}

:where(.css-dev-only-do-not-override-diro6f).ant-modal,
:where(.css-dev-only-do-not-override-diro6f)[class^="ant-btn"], 
:where(.css-dev-only-do-not-override-diro6f)[class*=" ant-btn"], 
:where(.css-dev-only-do-not-override-diro6f).ant-dropdown,
:where(.css-dev-only-do-not-override-diro6f)[class^="ant-dropdown-menu"], 
:where(.css-dev-only-do-not-override-diro6f)[class*=" ant-dropdown-menu"],
:where(.css-dev-only-do-not-override-diro6f)[class^="ant-empty"], 
:where(.css-dev-only-do-not-override-diro6f)[class*=" ant-empty"],
:where(.css-dev-only-do-not-override-diro6f).ant-tooltip,
:where(.css-dev-only-do-not-override-diro6f).ant-table-wrapper .ant-table{
    font-family: 'Alegreya',Georgia,Times,Times New Roman,Serif !important;
}