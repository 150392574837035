.menubar {
    display: flex;
    align-items: center;
    background: #2A68AF;
    width: 100%;
    height: 80px;
}

.menubar-logo-img {
    width: 60px;
    height: 60px;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
}

.menubar-user {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.menubar-user .user-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.down-arrow {
    width: 14px;
    height: 8px;
}

.user-profile-menu {
    top: 82px !important;
}

.user-profile-menu.ant-dropdown .ant-dropdown-menu {
    background-color: #2A68AF;
}

.user-profile-menu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}

.ant-tooltip {
    max-width: 40%;
    --antd-arrow-background-color: #ffffff;
}

.ant-tooltip .ant-tooltip-inner {
    background-color: #FFFFFF;
    color: #616060;
    padding: 6px 10px;
    text-align: justify;
}

.cursorP {
    cursor: pointer;
}

.menu {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    cursor: pointer;
}

a.menu:hover {
    color: #FFFFFF;
}